.front {
    footer {
        padding-top: 0;
    }

    .locations-callout-home {
        position: relative;
        overflow: hidden;

        p {
            margin-bottom: 0;
            overflow: hidden;
            background: $wcm-dark-orange;
        }

        &:hover {
            img {
                @include scale(1.1);
            }

            a span {
                text-decoration: underline;
            }
        }

        .locations-callout {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                // background: $wcm-dark-orange;
                // mix-blend-mode: multiply;
            }

            a {
                border-bottom: none;
                color: $wcm-white;
                position: relative;
                display: block;
                width: 100%;
                height: 100%;

                span {
                    @include center();
                    font-size: 36px;
                    font-weight: 700;
                    @include breakpoint($xs) {
                        font-size: 26px;
                        width: 100%;
                        text-align: center;
                    }

                    &:after {
                        content: '\e80d';
                        color: $wcm-white;
                        @include fontello();
                        font-size: 70%;
                        padding-left: 10px;
                        text-decoration: none;
                    }
                }  
            }
        }
        img {
            @include transition(all 0.25s);
            width: 100%;
            // background: $wcm-dark-orange;
            mix-blend-mode: multiply;
        }
    }

    .hidden-link {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        font: 0/0 a;
    }

    .annual-brochure {
        position: relative;

        img {
            border: 2px solid $wcm-border-gray;
        }

        .pdf-icon {
            @include center();
            .cta-icon {
                color: $wcm-dark-orange;
                fill: $wcm-bright-orange;
                width: 70px;
                height: 70px;
            }
        }
    }

    .content-locations {
        background: $wcm-black;
        .view-locations-and-providers {
            .view-content {
                display: none;
            }
        }
        #locations-map {
            height: 250px;
            @include breakpoint($sm) {
                height: 350px;
            }
            margin-top: 0;
            margin-bottom: 0;
        }

    }

    .content-top {
        @include box-shadow(0 15px 40px 0px $wcm-border-gray);
        position: relative;
        z-index: 1;
    }
    .middle-column {

        .row {
            &:before {
                display: block;
            }

            .col-sm-7, .col-sm-5 {
                @include breakpoint($xs) {
                    width: 100%;
                }
            }
        }
        .pane-bundle-text {
            text-align: center;
            padding-bottom: 20px;
        }

        .wcc-connect {
            padding: 70px 35px 30px;
            border: 1px solid $wcm-border-gray;
            background: $wcm-bg-gray;
            height: 100%;
            flex: 1;
            text-align: center;

            > a.appointment-link{
                white-space: nowrap;
                border: 3px solid $wcm-bright-orange;
                border-radius: 22px;
                display: inline-block;
                margin-bottom: 15px;
                padding: 8px 32px 8px 32px;

                &:after{
                    content: "";
                    padding: 0;
                }

                .make-appt {
                    font-size: 16px;
                }
            }

            .phone {
                display: block;
                text-align: center;
                font-size: 32px;
                font-family: $wcm-bold;
                color: $wcm-black;
            }

            .wcc-connect-callout {
                text-align: center;
                font-size: 28px;
                margin-top: 10px;
                font-family: $wcm-bold;
                color: $wcm-red;

                span {
                    font-family: $wcm-regular;
                    color: $wcm-red;
                }
            }
            .wcc-connect-callout-subtext {
                text-align: center;

                a {
                    color: $wcm-red;
                }
            }

            .cta-container {
                text-align: center;
                .cta-icon {
                    color: $wcm-dark-orange;
                    fill: $wcm-bright-orange;
                    width: 60px;
                    height: 60px
                }
            }

            hr.hr-small {
                width: 80%;
            }

            form {
                label {
                    color: $wcm-med-gray;
                    font-size: 14px;
                    font-weight: 400;
                }

                .mychart-link {
                    float: right;
                    color: $wcm-med-gray;
                    font-size: 13px;
                    &:after {
                        display: none;
                    }
                }

                input[type="submit"] {
                    float: right;
                    clear: both;
                    margin-top: 10px;
                }
            }
        }
    }

    .events-pane {
        margin-top: 50px;
        margin-bottom: 50px;

        h3.pane-title {
            margin-bottom: 20px;
            color: $wcm-med-gray;
            font-size: 14px;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }

    .localist-calendar-widget {

        .all-events-btn {
            clear: both;
            @include breakpoint($sm) {
                width: 50%;
            }
            text-align: center;
            padding-top: 15px;
        }
        .localist_minicalendar_nav {
            display: none;
        }

        .localist_minicalendar {
            @include breakpoint($sm) {
                float: left;
                width: 48%;
                margin-right: 4%;
            }
        }

        .localist_minicalendar_events {
            @include breakpoint($sm) {
            float: left;
            width: 48%;
                margin-top: 0;
            }

            #lw ul li.lwe {

                padding-left: 40px;
                position: relative;
                padding-bottom: 20px;
                margin-bottom: 25px;
                border-bottom: 1px solid $wcm-border-gray;

                &:last-child {
                    border-bottom: none;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background: url(../../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/calendar.svg);
                    width: 30px;
                    height: 30px;
                    background-size: 30px 30px;
                    // background-size: 593px 143px;
                    // background-position: -188px -38px;                    
                }

                .lwn {
                    a {
                        font-size: 18px;
                        line-height: 22px;
                    }
                    // padding-left: 40px;
                    .lwn0 {
                        background: none; 
                        width: 100%;
                        height: auto;
                        display: block;
                        text-align: left;
                        padding: 0;
                        margin-bottom: 10px;
                        float: none;
                        color: $wcm-med-gray;
                        font-size: 13px;
                        text-transform: uppercase;
                    }
                }

                .lwl {
                    padding-top: 15px;
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }

    .callout-middle {
        padding: 80px 20px;
        color: $wcm-white;
        position: relative;
        background: $wcm-black;

        /*background: url(../images/for_professionals_bg.jpg) no-repeat;
        background-size: 50% 100%;
        background-position: right; */

        .callout-middle-bg-left {
            background: url(../images/ForPatients.jpg) no-repeat;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
            @include breakpoint($sm) {
                left: 0;
                top: 0;
                width: 50%;
                height: 100%;
            }
        }
        .callout-middle-bg-right {
            background: url(../images/for_professionals_bg.jpg) no-repeat;
            background-size: cover;
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 50%;
            @include breakpoint($sm) {
                left: 50%;
                top: 0;
                width: 50%;
                height: 100%;
            }
        }
        /*&:before {
            content: '';
            background: url(../images/for_professionals_bg.jpg) no-repeat;
            background-size: cover;
            position: absolute;
            left: 50%;
            top: 0;
            width: 50%;
            min-height: 460px;
        }

        &:after {
            content: '';
            background: url(../images/for_professionals_bg.jpg) no-repeat;
            background-size: cover;
            position: absolute;
            left: 50%;
            top: 0;
            width: 50%;
            min-height: 460px;
        }*/

        .col-sm-6 {
            height: 300px;

            @include transition(all 0.25s);

            .callout-container {
                @include breakpoint($sm) {
                    padding: 0 60px;
                }
                @include v-center;
            }

            // background: $wcm-bright-orange;
            // mix-blend-mode: multiply;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                background: $wcm-dark-orange;
                // background: $wcm-dark-gray;
                mix-blend-mode: multiply;
            }

            h2 {
                text-align: center;
            }
            h2, p {
                color: $wcm-white;
                position: relative;
                // text-shadow: 2px 2px 2px #000;
                // @include transition(all 0.25s);
            }

            p {
                @include breakpoint($sm) {
                    display: none;
                }
            }

            .cta-icon-container {
                // padding: 20px 0;
                position: relative;

                // mix-blend-mode: multiply;
                text-align: center;
                .cta-icon {
                    position: relative;
                    margin: 0 auto;
                    color: $wcm-dark-orange;
                    fill: $wcm-bright-orange;
                    width: 70px;
                    height: 70px;
                }
            }

            &:hover {
                @include breakpoint($sm) {

                    &:before {
                        background: $wcm-red;
                    }

                    .cta-icon {
                        color: $wcm-white;
                        fill: $wcm-white;
                    }

                    p {
                        // max-height: 0;
                        // overflow: hidden;
                        // visibility: hidden;
                        display: block;
                    }
                }
            }
        }
    }

    .pane-clinical-specialties-panel-pane-1 {
        padding-top: 40px;
        margin-bottom: 30px;

        h3.pane-title {
            margin-bottom: 20px;
            color: $wcm-med-gray;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        .views-row {
            border-bottom: 1px solid $wcm-border-gray;
            &:first-child {
                border-top: 1px solid $wcm-border-gray;
            }

            a {
                display: block;
                position: relative;
                padding: 12px 30px 12px 12px;
                text-decoration: none;

                &:after {
                    content: '\e80d';
                    color: $wcm-dark-orange;
                    position: absolute;
                    right: 12px;
                    top: 16px;
                    @include fontello();
                }

                &:hover {
                    @include box-shadow(0 0 6px 2px $wcm-border-gray);
                    background: $wcm-white;
                }
            }
        }
    }

    .home-callout-residency {
        background: $wcm-black;
        padding-top: 80px;
        padding-bottom: 80px;
        @include breakpoint($sm) {
            padding-top: 125px;
            padding-bottom: 125px;
        }
        background: url(../images/residency_program_callout.jpg) no-repeat top center;
        background-size: cover;
        .callout-background {
            // background: rgba(232, 119, 34, 0.8);
            // background: $wcm-bright-orange;
            color: $wcm-white;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;

            &:before {
                content: '';
                left: 0;
                top: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                background: $wcm-bright-orange;
                mix-blend-mode: multiply;
            }

            h2 {
                color: $wcm-white;
                margin-bottom: 20px;
                font-size: 20px;
                @include breakpoint($sm) {
                    font-size: 30px;
                }

                a {
                    color: $wcm-white;
                }
            }

            p {
                font-size: 16px;
                @include breakpoint($sm) {
                    font-size: 24px;
                }
            }
        }
    }

    .home-doormat {
        // background: url(../images/doormat_bg.jpg) no-repeat top center;
        // background-size: cover;
        // color: $wcm-white;
        padding-top: 50px;
        padding-bottom: 50px;
        // padding: 50px 0;
        @include breakpoint($xs) {
            padding-left: 40px;
            padding-right: 40px;
        }

        .doormat {
            .row.is-flex {

                &:before {
                    display: block;
                }

                display: block;
                @include breakpoint($md) {
                    display: flex;
                }
            }
        }

        .col-md-4, .col-md-3 {

            border-bottom: 1px solid $wcm-border-gray;
            &:last-child {
                border-bottom: none;
            }

            @include breakpoint($md) {
                border-bottom: none;
                border-right: 1px solid $wcm-border-gray;

                &:last-child {
                    border-right: none;
                }
            }

            h3 {
                margin-bottom: 20px;
                // color: $wcm-white;
                font-size: 24px;
                /*@include breakpoint($sm) {
                    font-size: 30px;
                }*/

                a {
                    // color: $wcm-white;

                    &.external-link {
                        &:after {
                            // color: $wcm-bright-orange;
                            // padding-left: 4px;

                            display: inline-block;
                            content: '';
                            background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg);
                            background-size: 15px 15px;
                            height: 15px;
                            width: 15px;
                            position: relative;
                            // left: 0;
                            top: 4px;

                        }
                    }

                    &:after {
                        // color: $wcm-white;
                    }
                }
            }

            .doormat-entry {
                // padding: 20px 10px 30px;
                padding: 0px 0px 10px;
                @include breakpoint($md) {
                    padding: 0px 50px 30px;
                }
            }

            .doormat-entry__description {
                font-size: 16px;
                @include breakpoint($sm) {
                    // font-size: 24px;
                    font-size: 18px;
                }
            }

            &:hover {
                &:before {
                    background: $wcm-red !important;
                    // background: rgba(179, 27, 27, 0.8) !important;
                }
            }
        }

        .col-md-3 {
            h3 {
                font-size: 24px;
            }

            .doormat-entry {
                // padding: 20px 10px 30px;
                padding: 0px 0px 10px;
                @include breakpoint($md) {
                    padding: 0px 10px 30px;
                }
            }

            .doormat-entry__description {
                @include breakpoint($sm) {
                    font-size: 18px;
                }
            }
        }

        /*.col-md-4, .col-md-3 {
            .doormat-entry {
                position: relative;
            }
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: $wcm-bright-orange;// rgba(232, 119, 34, 0.8);
                mix-blend-mode: multiply;
            }
        }*/

        .col-md-4:nth-child(1), .col-md-3:nth-child(1) {
            /*.doormat-entry {
                position: relative;
            }
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: $wcm-bright-orange;// rgba(232, 119, 34, 0.8);
                mix-blend-mode: multiply;
            }*/
            // mix-blend-mode: multiply;
                // background: rgba(232, 119, 34, 0.8);
                // background: $wcm-bright-orange;
            // }
        }
        /*.col-md-4:nth-child(2), .col-md-3:nth-child(2) {
            &:before {
                background: $wcm-yellow;
            }
            // background: rgba(255, 199, 44, 0.8);
            // background: $wcm-yellow;
        }
        .col-md-4:nth-child(3), .col-md-3:nth-child(3) {
            &:before {
                background: $wcm-dark-orange;
            }
            // background: rgba(207, 69, 32, 0.8);
            // background: $wcm-dark-orange;
        }*/
    }

    .btn-home {
        font-size: 13px;
        padding: 5px 20px;
    }
}