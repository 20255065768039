.brand__lockup img {
    height: 84px;
}

.title-bar {
    font-family: $wcm-regular;
    padding-top: 5px;
    @include breakpoint($xs) {
        font-size: 18px;
    }
}

.block-menu-block {
    @include breakpoint($md) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;
    }
}

.site-main .btn {
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $wcm-white;
        background-color: $wcm-red;
        border-color: $wcm-red;
    }
  }

  a {
    &:hover,
    &:focus {
      color: $wcm-red;
    }
  }

  #active-third-level-nav .level-3-select a {
    color: $wcm-dark-orange;
  }
  .footer-contact__social-links a {
    &:focus {
      color: $wcm-white;
    }
  }
  .footer-contact__site-name a {
    border: none;
    &:hover, &:focus {
      color: $wcm-white;
      text-decoration: underline;
      border: none;
    }
  }

.pagination {
    > li > a,
    > li > span {
        &:hover,
        &:focus {
        color: $wcm-red;
        border: 1px solid $wcm-border-gray;
        }
    }
}

.thumbnail-controller .pager {
    color: $wcm-dark-orange;
}

.form-required {
    color: $wcm-red;
}

#modal-content .ctools-auto-submit-full-form .form-actions {
    position: relative;
    z-index: 1;
}

// .btn-news-sidebar {
.ent-btn-pane {
    margin-bottom: 10px;
}
.btn--wcm {
    padding-top: 10px;
    padding-bottom: 10px;

    &:before {
        line-height: 2.5;
    }
}

#primary-nav .level-1 {
width: 15%;
padding: 10px 0;
margin: 0 7px;}

.ent-phone-number {
    display: block;
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
    font-family: $wcm-regular;
}

.nyp-ent-callout {
    background: $wcm-bg-gray;
    text-align: center;
    padding: 40px 0;
    margin: 40px 0;
    position: relative;

    a {
        position: relative;
        padding-left: 55px;
        &:before {
            display: inline-block;
            content: '';
            background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/education.svg);
            background-size: 40px 40px;
            height: 40px;
            width: 40px;
            position: absolute;
            left: 0;
            top: -8px;
        }
    }
}

.ways-to-give-callout {
    border-top: 1px solid $wcm-border-gray;
    margin-top: 30px;
    padding-top: 30px;
    position: relative;
    padding-left: 90px;
    clear: both;

    &:before {
        display: inline-block;
        content: '';
        background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/give.svg);
        background-size: 64px 64px;
        height: 64px;
        width: 64px;
        position: absolute;
        left: 0;
    }
}

.professionals-callout {

    .row {
        margin-left: -5px;
        margin-right: -5px;
    }
    .col-md-4 {
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 20px;
    }

    .doormat-entry {
        height: 100%;
        position: relative;

        /*.doormat-entry__image + .doormat-entry__title {
            position: absolute;
        }*/

        .doormat-entry__image {
            overflow: hidden;
            img {
                @include transition(all 0.25s);
            }
        }

        &:hover {
            .doormat-entry__image {
                img {
                    @include scale(1.1);
                }
            }

            .doormat-entry__title {
                h3 {
                    &:before {
                        background: rgba(232, 119, 34, 1);
                        mix-blend-mode: normal;
                    }
                }
            }
        }

        .doormat-entry__title {
            position: absolute;
            width: 100%;
            bottom: 0;

            h3 {
                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    background: rgba(207, 69, 32, 0.9);
                    mix-blend-mode: multiply;
                }
                font-family: $wcm-regular;
                color: $wcm-white;
                // background: rgba(207, 69, 32, 0.9);
                // background: rgba(179, 27, 27, 0.9);
                text-align: center;
                margin-top: 0;
                // padding: 20px 0;

                a {
                    position: relative;
                    display: block;
                    padding: 20px 0;
                    color: $wcm-white;
                    &:not(.external-link) {
                        &:after {
                            display: none;
                        }
                    }
                    &:after {
                        color: $wcm-white;
                    }
                }
            }

            // &:hover {
            //     h3 {
            //         background: rgba(207, 69, 32, 1);
            //     }
            // }
        }
    }
}

.for-patients-callouts {
    @include clearfix();
    margin-top: 50px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 50px;

    .callout-wrapper {
        text-align: center;
        margin-bottom: 20px;
        @include breakpoint($xs) {
            width: 100%;
        }
        @include breakpoint($sm) {
            border-right: 2px solid $wcm-border-gray;
        }

        h3 {
            margin-bottom: 10px;
        }

        a {
            margin-top: 20px;
            display: inline-block;
            font: 0/0 a;
            border-bottom: 0;
            position: relative;
            &:after {
                content: '\e80d';
                position: relative;
                // top: 42%;
                // right: 10px;
                border: 2px solid $wcm-border-gray;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                color: $wcm-bright-orange;
                @include fontello();
                font-size: 15px;
                line-height: 40px;
                padding-left: 3px;
                text-decoration: none;
            }

            &:hover {
                &:after {
                    color: $wcm-white;
                    background: $wcm-bright-orange;
                }
            }
        }

        /*&:after {
            content: '';
            @include fontello();
        }*/

        &:nth-child(1) {
            &:before {
                display: block;
                content: '';
                background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/location.svg);
                background-size: 64px 64px;
                height: 64px;
                width: 64px;
                margin: 0 auto;
                position: relative;
            }
        }

        &:nth-child(2) {
            h3 {
                span {
                    font-family: $wcm-regular;
                }
            }
            &:before {
                display: block;
                content: '';
                background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/portal.svg);
                background-size: 64px 64px;
                height: 64px;
                width: 64px;
                margin: 0 auto;
                position: relative;
            }
        }

        &:nth-child(3) {
            &:before {
                display: block;
                content: '';
                background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/publications.svg);
                background-size: 64px 64px;
                height: 64px;
                width: 64px;
                margin: 0 auto;
                position: relative;
            }
        }

        &:last-child {
            border-right: none;
        }
    }
}

.field-name-field-news-category, .views-field-field-news-category {
    a {
        color: $wcm-dark-orange;
        font-size: 14px;
        background: url(../images/tag.png) 0 0 no-repeat;
        background-size: 16px 16px;
        padding-left: 25px;
        position: relative;
    }
}

.pane-news-panel-pane-1 {
    margin-top: 40px;

    .pane-title {
        margin-bottom: 20px;
    }

    .view-filters {
        margin-bottom: 20px;
        .views-exposed-form {
            border: 1px solid $wcm-border-gray;
            background: $wcm-bg-gray;

            .views-exposed-widgets {
                margin: 15px 0;
                text-align: center;
            }
            .views-exposed-widget {
                float: none;
                // display: inline-block;

                &.views-submit-button {
                    // float: left;
                }
            }
            label {
                padding-right: 10px;
                // float: left;
                font-size: 13px;
                display: inline;
                // display: inline-block;
            }
            .views-widget {
                // float: left;
                display: inline;
                // display: inline-block;
            }
        }
    }

    .view-teaser {
        border-bottom: 1px solid $wcm-border-gray;
        padding-bottom: 20px;

        .teaser-image {
            img {
                @include breakpoint($sm) {
                    float: right;
                    margin-left: 20px;
                    margin-right: 0;
                }
            }
        }
    }
}

.pane-news-panel-pane-4 {
    .view-teaser {
        border-bottom: 1px solid $wcm-border-gray;
        padding-bottom: 20px;

        .teaser-image {
            img {
                @include breakpoint($sm) {
                    float: right;
                    margin-left: 20px;
                    margin-right: 0;
                }
            }
        }
    }
}

.pane-brochure-library-panel-pane-1 {

    .views-exposed-form .views-exposed-widget {
        float: none;

        label {
            font-size: 13px;
        }
    }

    .views-row {
        clear: both;
        border-bottom: 1px solid $wcm-border-gray;
        // padding: 15px 0;
        &:hover {
            background: $wcm-bg-gray;
        }
    }

    .views-field-field-brochure-file {
        a {
            position: relative;
            display: block;
            padding: 10px 0 15px 45px;
            z-index: 1;

            &:before {
                content: '';
                display: inline-block;
                background-repeat: no-repeat;
                // background: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
                // background: url(../../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
                background-image: url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/pdf.svg);
				background-size: 27px 27px;
                width: 27px;
                height: 27px;
                // background-position: -31px -92px;
                // background-size: 1900%;
                // calculation: sprite site: 1264px 304px
                // desired size = 25 / original size= 64
                // bg width = 1264 * 0.390625
                // bg height = 304 * 0.390625
                // background-size: 494px 119px;
                margin-right: 10px;
                position: absolute;
                left: 5px;
                top: 10px;
            }

            &:after {
                content: '\e80d';
                color: $wcm-dark-orange;
                position: absolute;
                right: 20px;
                top: 18px;
                @include fontello();
                // text-decoration: none;
            }

            &:hover {
                padding-left: 60px;
                text-decoration: none;
                // background: $wcm-bg-gray;
                &:before {
                    width: 40px;
                    height: 40px;
                    background-image: url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/download.svg);
                    background-size: 40px 40px;
                    // background-size: 790px 190px;
                    // background-position: -100px -50px !important;
                    top: 5px;
                    // background-position: -62px -28px !important;
                }
            }
        }

        .icon-doc {
            a:before {
                background-position: 0 -92px;
            }
        }
        .icon-ppt {
            a:before {
                background-position: -62px -92px;
            }
        }
        .icon-xls {
            a:before {
                background-position: -156px -92px;
            }
        }

        .cta-icon {
            color: $wcm-dark-orange;
            fill: $wcm-bright-orange;
            width: 30px;
            height: 25px;
            float: left;
            margin-right: 15px;
            // position: relative;
            // z-index: 1;
            margin-top: 15px;
        }

        .file-icon {
            display: none;
        }
    }
}

.pane-brochure-library-panel-pane-2 {
    border-bottom: 1px solid $wcm-border-gray;
    padding-bottom: 20px;
}

.view-locations-and-providers {
    .form-control {
        max-width: none;
    }

    .views-row {
        margin-bottom: 20px;
        padding-left: 30px;

        &:hover {
            color: $wcm-bright-orange;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            background: url(../images/icon-location.png) no-repeat;
            width: 20px;
            height: 30px;
            background-size: 18px;
        }
    }
}

.pane-locations-and-providers-panel-pane-2 {
    .view-content {
        display: none;
    }
    .views-row:before {
        display: none;
    }
}

.node-webform {
    border-top: 1px solid $wcm-border-gray;
    h2 {
        display: none;
    }

    .form-control {
        max-width: none;
    }

    .col-sm-6:nth-child(odd) {
        padding-left: 0;
        padding-right: 0;

        @include breakpoint($sm) {
            padding-left: 0;
            padding-right: 20px;
        }
    }
    .col-sm-6:nth-child(even) {
        padding-left: 0;
        padding-right: 0;
        @include breakpoint($sm) {
            padding-left: 20px;
            padding-right: 0;
        }
    }

    .webform-component-textarea {
        textarea {
            max-width: none;
        }
    }
}

.contact-anchor-wrapper {
    text-align: center;

    .contact-anchor {
        font-weight: bold;
        font-size: 14px;
    }

    .btn-contact-anchor {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;

        @include breakpoint( (min: 515px) ) {
            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}

.view-conditions {
    .views-exposed-form .views-exposed-widget {
        float: none;

        label {
            font-size: 13px;
        }
    }

    .views-row {
        border-bottom: 1px solid $wcm-border-gray;
        a {
            display: block;
            position: relative;
            padding: 12px;
            font-weight: 700;
            text-decoration: none;

            &:after {
                content: '\e80d';
                color: $wcm-dark-orange;
                position: absolute;
                right: 12px;
                top: 16px;
                @include fontello();
            }

            &:hover {
                background: $wcm-bg-gray;
                // @include box-shadow(0 0 6px 2px $wcm-border-gray);
            }
        }
    }
}

#locations-map {
    height: 280px;
    width:100%;
    margin-top: 10px;
    margin-bottom: 20px;
}

.address-location {
    cursor: pointer;

    &:hover {
        color: $wcm-bright-orange;
    }
}
.pane-node-field-specialty-locations {
    margin-top: 40px;

    .container {
        margin-left: 0;
        margin-right: 0;

        .col-sm-6 {
            margin-bottom: 20px;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                background: url(../images/icon-location.png) no-repeat;
                width: 20px;
                height: 30px;
                background-size: 18px;
            }

            .address-location {
                padding-left: 10px;
            }
        }
    }
}


.pane-profiles-panel-pane-3,
.pane-profiles-panel-pane-4,
.pane-profiles-panel-pane-5,
.pane-profiles-panel-pane-6,
.pane-profiles-panel-pane-7,
.pane-profiles-panel-pane-9 {
    clear: both;

    .views-row {

        .views-field-field-profile-image {
            padding-bottom: 10px;
        }
        .views-field-field-professional-title {
            padding-bottom: 10px;
        }

        .display-name,
        .views-field-field-professional-title,
        .views-field-field-pops-profile-link,
        .views-field-field-vivo-profile {
            font-size: 13px;
        }

        margin-bottom: 30px;
        &:nth-child(3n+4) {
            clear: both;
            // padding-left: 0;
            // padding-right: 10px;
        }
    }
}

.pane-profiles-panel-pane-3 {
    .pane-title {
        margin-bottom: 20px;
    }
}

.sub-service-providers-title {
    margin-bottom: 20px;
    color: #777;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: $wcm-bold;
}

.sub-service-provider {
    font-size: 13px;

    .profile-image {
        padding-bottom: 10px;
    }
    .profile-titles {
        padding-bottom: 10px;
    }
}

.pane-profiles-panel-pane-4, .pane-profiles-panel-pane-9 {
    @include clearfix();
    border-top: 1px solid $wcm-border-gray;

    .pane-title {
        margin-bottom: 20px;
    }
}

.pane-profiles-panel-pane-7 {
    @include clearfix();
    .pane-title {
        margin-bottom: 20px;
    }
}

.pane-locations-and-providers-panel-pane-1 {
    margin-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $wcm-border-gray;
}

.pane-node-field-sub-services {
    clear: both;

    .element__hide {
        padding-bottom: 20px;
    }
}

#views-exposed-form-locations-and-providers-panel-pane-1 {
    .views-submit-button {
        display: none;
    }

    .views-exposed-form {
        border: 1px solid $wcm-border-gray;
        background: $wcm-bg-gray;
        padding: 25px;
    }

    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $wcm-border-gray;

    div.filter-description {
        font-family: $wcm-bold;
        font-size: 13px;
        text-transform: uppercase;
        color: $wcm-med-gray;
        letter-spacing: 2px;
        clear: both;
    }

    .views-widget-filter-nid_1 {
        // display: block;
        float: none;
        width: 100%;
        max-width: 400px;
        margin-bottom: 30px;

        .select2-container {
            width: 100% !important;
        }

        .form-control {
            max-width: 50%;
        }
    }

    // .views-widget-filter-nid {
    //     clear: both;
    // }
}

.pane-clinical-specialties-panel-pane-1 {
    clear: both;
    margin-bottom: 20px;

    .pane-title {
        margin-bottom: 15px;
    }

    .views-row {
        border-bottom: 1px solid $wcm-border-gray;
        &:first-child {
            border-top: 1px solid $wcm-border-gray;
        }

        a {
            display: block;
            position: relative;
            padding: 12px 30px 12px 12px;
            font-weight: 700;
            text-decoration: none;

            &:after {
                content: '\e80d';
                color: $wcm-dark-orange;
                position: absolute;
                right: 12px;
                top: 16px;
                @include fontello();
            }

            &:hover {
                background: $wcm-bg-gray;
                // @include box-shadow(0 0 6px 2px $wcm-border-gray);
            }
        }
    }
}

.pane-clinical-specialties-panel-pane-2 {
    clear: both;
    margin-bottom: 20px;

    .pane-title {
        margin-bottom: 15px;
    }

    .condition-list {
        border-bottom: 1px solid $wcm-border-gray;
        &:first-child {
            border-top: 1px solid $wcm-border-gray;
        }

        a {
            display: block;
            position: relative;
            padding: 12px 30px 12px 12px;
            font-weight: 700;
            text-decoration: none;

            &:after {
                content: '\e80d';
                color: $wcm-dark-orange;
                position: absolute;
                right: 12px;
                top: 16px;
                @include fontello();
            }

            &:hover {
                background: $wcm-bg-gray;
                // @include box-shadow(0 0 6px 2px $wcm-border-gray);
            }
        }
    }
}

.pane-clinical-specialties-panel-pane-3 {
    margin-bottom: 30px;
    .select2-container {
        width: 100% !important;
        // max-width: 255px;
    }
}

.pane-education-videos-panel-pane-1 {
    h3 {
        clear: both;
        border-top: 1px solid $wcm-border-gray;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .views-row {
        &:nth-child(3n+3) {
            clear: both;
            padding-left: 0;
            padding-right: 10px;
        }
        &:nth-child(3n+1) {
            padding-left: 5px;
            padding-right: 5px;
        }
        &:nth-child(3n+2) {
            padding-left: 10px;
            padding-right: 0;
        }
    }
}

.view-residents {
    .views-row {
        margin-bottom: 20px;

        &.views-row-odd {
            clear: both;
        }
    }
}

.pane-residents-panel-pane-6 {
    .pane-title {
        margin-bottom: 20px;
    }
    .view-residents {
        .col-sm-3 {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 20px;

            @include breakpoint($sm) {
                padding-left: 0;
                padding-right: 10px;
            }
        }

        .col-sm-9 {
            padding-left: 0;
            padding-right: 0;

            @include breakpoint($sm) {
                padding-left: 10px;
                padding-right: 0;
            }
        }
    }
}

.wcc-connect-pane {
    padding: 30px 35px 30px;
    @include breakpoint($sm) {
        padding: 30px 100px 30px;
    }
    border: 1px solid $wcm-border-gray;
    background: $wcm-bg-gray;
    height: 100%;

    .wcc-connect-callout {
        text-align: center;
        font-size: 28px;
        @include breakpoint($sm) {
            font-size: 40px;
        }
        margin-top: 10px;
        font-family: $wcm-bold;
        color: $wcm-red;

        span {
            font-family: $wcm-regular;
            color: $wcm-red;
        }
    }


    form {
        label {
            color: $wcm-med-gray;
            font-size: 14px;
            font-weight: 400;
        }

        .form-control {
            max-width: none;
        }

        .mychart-link {
            float: right;
            font-size: 13px;
            &:after {
                display: none;
            }
        }

        .wcc-connect-footer {
            margin-top: 20px;
            text-align: right;

            input[type="submit"] {
                margin-left: 10px;
                font-size: 13px;
                padding: 5px 20px;
            }

            a {
                font-size: 13px;
                &:after {
                    display: none;
                }
            }
        }

        /*input[type="submit"] {
            float: right;
            clear: both;
            margin-top: 10px;
        }*/
    }
}

.pane-wcmc-contact-pane {
    .view-conditions, .view-clinical-specialties, .view-services {
        .view-content {
            font-weight: 700;
            color: $wcm-med-gray;
            padding-bottom: 12.5px;
        }
    }
}

/* Localist Calendar */
.localist-calendar-widget {
    margin-top: 20px;

    .localist_minicalendar {
        position: relative;
    }

    .localist_minicalendar_page_heading {
        h1 {
            margin-bottom: 0;
            margin-top: 0;
            font-size: 1.5rem !important;
            padding-bottom: 0;
            padding-top: 4px;
            color: $wcm-black !important;
        }

        border: 1px solid $wcm-border-gray;
        height: 40px !important;
        border-bottom: none;
        color: $wcm-black;
        margin-bottom: 0;
    }

    .localist_minicalendar .localist_minicalendar_pager a {
        // text-indent: -9999px;
        background: none !important;
        position: relative;
        font-size: 0;
        padding: 13px 10px;
        color: $wcm-red;
        border-bottom: none;

        &:before {
            color: $wcm-red;
            font-size: 1.3rem;
            content: ' \e810';
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            width: 1em;
            margin-right: .2em;
            text-align: center;
            font-variant: normal;
            text-transform: none;
            line-height: 1em;
            margin-left: .2em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        &.right {
            &:before {
            color: $wcm-red;
            font-size: 1.3rem;
            content: ' \e802';
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            width: 1em;
            margin-right: .2em;
            text-align: center;
            font-variant: normal;
            text-transform: none;
            line-height: 1em;
            margin-left: .2em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            }
        }
    }

    .localist_minicalendar_minicalendar {
        width: 100%;
        border: 1px solid $wcm-border-gray;

        .localist_minicalendar_dimday a {
            color: $wcm-med-gray;
            &:hover {
                color: $wcm-white;
            }
        }

        caption {
            display: none;
        }

        tbody {
            border-top: none;
        }

        th {
            background: #fff;
            color: #000;
            border: none;
            padding: 7px 0;
            font-size: 13px;
            font-weight: 600;
                /*&:last-child {
                    border-right: 1px solid #ccc;
                }*/
        }

        td {
            background-color: $wcm-bg-gray;
            border: none;
            font-weight: normal;
            padding: 4px 0;

            &.localist_minicalendar_today {
                @include breakpoint( (max: 359px) ) {
                    background-color: $wcm-dark-orange;
                    a {
                        width: auto;
                        height: auto;
                        color: $wcm-white;
                    }
                }
                a {
                    @include breakpoint( (min: 360px) ) {
                        background-color: $wcm-dark-orange;
                        color: $wcm-white;
                        font-weight: normal;
                    }
                }
            }

            a {
                @include breakpoint( (max: 359px) ) {
                    width: auto;
                    height: auto;
                    line-height: inherit;
                }
                height: 45px;
                line-height: 45px;
                width: 45px;
                display: inline-block;
                border-radius: 45px;
                border: none;

                &:hover {
                    background-color: $wcm-dark-orange;
                    font-weight: normal;
                }
            }
        }
    }

    .localist_minicalendar_events {
        margin-top: 20px;
        #lw {
        	font-family: inherit;

            ul {
                padding: 0;

                li.lwe {
                    padding: 0;
                    border-bottom: none;
                    margin-bottom: 10px;

                    // padding-left: 40px;
                    position: relative;
                    padding-bottom: 20px;
                    margin-bottom: 25px;
                    border-bottom: 1px solid $wcm-border-gray;

                    .lwd, .lwl {
                        font-size: inherit;
                        line-height: inherit;
                    }

                    .lwi0 {
                        float: right;
                        padding: 0;
                        margin-left: 10px;
                        margin-bottom: 15px;

                        .lwi {
                            padding: 0;
                            border: none;
                        }
                    }

                    .lwn0 {
                        width: 100%;
                        height: auto;
                        display: block;
                        text-align: left;
                        padding: 0;
                        margin-bottom: 10px;
                        float: none;
                        color: $wcm-med-gray;
                        font-size: 13px;
                        font-weight: 600;
                        text-transform: uppercase;
                        /*
                        // background: url(../../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
                        background: url(../images/calendar.svg) no-repeat;
                        background-size: 100%;
                        background-position: 0 0;
                        // background-position: -300px -60px;
                        width: 50px;
                        height: 50px;
                        position: relative;
                        float: left;
                        margin-right: 5px;
                        padding: 18px 10px 0px;
                        font-size: 13px;
                        font-weight: 600;
                        text-align: center;
                        line-height: 1;*/
                    }
                }
            }
        }
    }
}

/* ---- Accordion CSS ---- */
.ctools-collapsible-processed .ctools-collapsible-handle h2 {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: #cf4520;
    border: 1px solid #ddd;
    border-width: 3px 0 0;
    cursor: pointer;
    margin: 0;
    padding: 15px 55px 15px 10px;
    font-weight: 600;
}

.ctools-collapsible-processed .ctools-collapsible-handle h2:after {
    content: '-';
    width: 50px;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-left: 3px solid #ddd;
}

.ctools-collapsible-processed.ctools-collapsed .ctools-collapsible-handle h2:after {
    content: '+';
}

.ctools-collapsible-container .ctools-toggle {display:none;}

