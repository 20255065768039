/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
.brand__lockup img {
  height: 84px; }

.title-bar {
  font-family: "1898Sans-Regular", sans-serif;
  padding-top: 5px; }
  @media screen and (max-width: 767px) {
    .title-bar {
      font-size: 18px; } }

@media screen and (min-width: 992px) {
  .block-menu-block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em; } }

.site-main .btn:hover, .site-main .btn:focus, .site-main .btn.focus, .site-main .btn:active, .site-main .btn.active,
.open > .site-main .btn.dropdown-toggle {
  color: #fff;
  background-color: #b31b1b;
  border-color: #b31b1b; }

a:hover, a:focus {
  color: #b31b1b; }

#active-third-level-nav .level-3-select a {
  color: #cf4520; }

.footer-contact__social-links a:focus {
  color: #fff; }

.footer-contact__site-name a {
  border: none; }
  .footer-contact__site-name a:hover, .footer-contact__site-name a:focus {
    color: #fff;
    text-decoration: underline;
    border: none; }

.pagination > li > a:hover, .pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  color: #b31b1b;
  border: 1px solid #dddddd; }

.thumbnail-controller .pager {
  color: #cf4520; }

.form-required {
  color: #b31b1b; }

#modal-content .ctools-auto-submit-full-form .form-actions {
  position: relative;
  z-index: 1; }

.ent-btn-pane {
  margin-bottom: 10px; }

.btn--wcm {
  padding-top: 10px;
  padding-bottom: 10px; }
  .btn--wcm:before {
    line-height: 2.5; }

#primary-nav .level-1 {
  width: 15%;
  padding: 10px 0;
  margin: 0 7px; }

.ent-phone-number {
  display: block;
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
  font-family: "1898Sans-Regular", sans-serif; }

.nyp-ent-callout {
  background: #f7f7f7;
  text-align: center;
  padding: 40px 0;
  margin: 40px 0;
  position: relative; }
  .nyp-ent-callout a {
    position: relative;
    padding-left: 55px; }
    .nyp-ent-callout a:before {
      display: inline-block;
      content: '';
      background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/education.svg);
      background-size: 40px 40px;
      height: 40px;
      width: 40px;
      position: absolute;
      left: 0;
      top: -8px; }

.ways-to-give-callout {
  border-top: 1px solid #dddddd;
  margin-top: 30px;
  padding-top: 30px;
  position: relative;
  padding-left: 90px;
  clear: both; }
  .ways-to-give-callout:before {
    display: inline-block;
    content: '';
    background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/give.svg);
    background-size: 64px 64px;
    height: 64px;
    width: 64px;
    position: absolute;
    left: 0; }

.professionals-callout .row {
  margin-left: -5px;
  margin-right: -5px; }

.professionals-callout .col-md-4 {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px; }

.professionals-callout .doormat-entry {
  height: 100%;
  position: relative;
  /*.doormat-entry__image + .doormat-entry__title {
            position: absolute;
        }*/ }
  .professionals-callout .doormat-entry .doormat-entry__image {
    overflow: hidden; }
    .professionals-callout .doormat-entry .doormat-entry__image img {
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s; }
  .professionals-callout .doormat-entry:hover .doormat-entry__image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  .professionals-callout .doormat-entry:hover .doormat-entry__title h3:before {
    background: #e87722;
    mix-blend-mode: normal; }
  .professionals-callout .doormat-entry .doormat-entry__title {
    position: absolute;
    width: 100%;
    bottom: 0; }
    .professionals-callout .doormat-entry .doormat-entry__title h3 {
      font-family: "1898Sans-Regular", sans-serif;
      color: #fff;
      text-align: center;
      margin-top: 0; }
      .professionals-callout .doormat-entry .doormat-entry__title h3:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background: rgba(207, 69, 32, 0.9);
        mix-blend-mode: multiply; }
      .professionals-callout .doormat-entry .doormat-entry__title h3 a {
        position: relative;
        display: block;
        padding: 20px 0;
        color: #fff; }
        .professionals-callout .doormat-entry .doormat-entry__title h3 a:not(.external-link):after {
          display: none; }
        .professionals-callout .doormat-entry .doormat-entry__title h3 a:after {
          color: #fff; }

.for-patients-callouts {
  margin-top: 50px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 50px; }
  .for-patients-callouts:before, .for-patients-callouts:after {
    content: " ";
    display: table; }
  .for-patients-callouts:after {
    clear: both; }
  .for-patients-callouts .callout-wrapper {
    text-align: center;
    margin-bottom: 20px;
    /*&:after {
            content: '';
            @include fontello();
        }*/ }
    @media screen and (max-width: 767px) {
      .for-patients-callouts .callout-wrapper {
        width: 100%; } }
    @media screen and (min-width: 768px) {
      .for-patients-callouts .callout-wrapper {
        border-right: 2px solid #dddddd; } }
    .for-patients-callouts .callout-wrapper h3 {
      margin-bottom: 10px; }
    .for-patients-callouts .callout-wrapper a {
      margin-top: 20px;
      display: inline-block;
      font: 0/0 a;
      border-bottom: 0;
      position: relative; }
      .for-patients-callouts .callout-wrapper a:after {
        content: '\e80d';
        position: relative;
        border: 2px solid #dddddd;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        color: #e7751d;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
        font-size: 15px;
        line-height: 40px;
        padding-left: 3px;
        text-decoration: none; }
      .for-patients-callouts .callout-wrapper a:hover:after {
        color: #fff;
        background: #e7751d; }
    .for-patients-callouts .callout-wrapper:nth-child(1):before {
      display: block;
      content: '';
      background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/location.svg);
      background-size: 64px 64px;
      height: 64px;
      width: 64px;
      margin: 0 auto;
      position: relative; }
    .for-patients-callouts .callout-wrapper:nth-child(2) h3 span {
      font-family: "1898Sans-Regular", sans-serif; }
    .for-patients-callouts .callout-wrapper:nth-child(2):before {
      display: block;
      content: '';
      background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/portal.svg);
      background-size: 64px 64px;
      height: 64px;
      width: 64px;
      margin: 0 auto;
      position: relative; }
    .for-patients-callouts .callout-wrapper:nth-child(3):before {
      display: block;
      content: '';
      background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/publications.svg);
      background-size: 64px 64px;
      height: 64px;
      width: 64px;
      margin: 0 auto;
      position: relative; }
    .for-patients-callouts .callout-wrapper:last-child {
      border-right: none; }

.field-name-field-news-category a, .views-field-field-news-category a {
  color: #cf4520;
  font-size: 14px;
  background: url(../images/tag.png) 0 0 no-repeat;
  background-size: 16px 16px;
  padding-left: 25px;
  position: relative; }

.pane-news-panel-pane-1 {
  margin-top: 40px; }
  .pane-news-panel-pane-1 .pane-title {
    margin-bottom: 20px; }
  .pane-news-panel-pane-1 .view-filters {
    margin-bottom: 20px; }
    .pane-news-panel-pane-1 .view-filters .views-exposed-form {
      border: 1px solid #dddddd;
      background: #f7f7f7; }
      .pane-news-panel-pane-1 .view-filters .views-exposed-form .views-exposed-widgets {
        margin: 15px 0;
        text-align: center; }
      .pane-news-panel-pane-1 .view-filters .views-exposed-form .views-exposed-widget {
        float: none; }
      .pane-news-panel-pane-1 .view-filters .views-exposed-form label {
        padding-right: 10px;
        font-size: 13px;
        display: inline; }
      .pane-news-panel-pane-1 .view-filters .views-exposed-form .views-widget {
        display: inline; }
  .pane-news-panel-pane-1 .view-teaser {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 20px; }
    @media screen and (min-width: 768px) {
      .pane-news-panel-pane-1 .view-teaser .teaser-image img {
        float: right;
        margin-left: 20px;
        margin-right: 0; } }

.pane-news-panel-pane-4 .view-teaser {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .pane-news-panel-pane-4 .view-teaser .teaser-image img {
      float: right;
      margin-left: 20px;
      margin-right: 0; } }

.pane-brochure-library-panel-pane-1 .views-exposed-form .views-exposed-widget {
  float: none; }
  .pane-brochure-library-panel-pane-1 .views-exposed-form .views-exposed-widget label {
    font-size: 13px; }

.pane-brochure-library-panel-pane-1 .views-row {
  clear: both;
  border-bottom: 1px solid #dddddd; }
  .pane-brochure-library-panel-pane-1 .views-row:hover {
    background: #f7f7f7; }

.pane-brochure-library-panel-pane-1 .views-field-field-brochure-file a {
  position: relative;
  display: block;
  padding: 10px 0 15px 45px;
  z-index: 1; }
  .pane-brochure-library-panel-pane-1 .views-field-field-brochure-file a:before {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/pdf.svg);
    background-size: 27px 27px;
    width: 27px;
    height: 27px;
    margin-right: 10px;
    position: absolute;
    left: 5px;
    top: 10px; }
  .pane-brochure-library-panel-pane-1 .views-field-field-brochure-file a:after {
    content: '\e80d';
    color: #cf4520;
    position: absolute;
    right: 20px;
    top: 18px;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
  .pane-brochure-library-panel-pane-1 .views-field-field-brochure-file a:hover {
    padding-left: 60px;
    text-decoration: none; }
    .pane-brochure-library-panel-pane-1 .views-field-field-brochure-file a:hover:before {
      width: 40px;
      height: 40px;
      background-image: url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/download.svg);
      background-size: 40px 40px;
      top: 5px; }

.pane-brochure-library-panel-pane-1 .views-field-field-brochure-file .icon-doc a:before {
  background-position: 0 -92px; }

.pane-brochure-library-panel-pane-1 .views-field-field-brochure-file .icon-ppt a:before {
  background-position: -62px -92px; }

.pane-brochure-library-panel-pane-1 .views-field-field-brochure-file .icon-xls a:before {
  background-position: -156px -92px; }

.pane-brochure-library-panel-pane-1 .views-field-field-brochure-file .cta-icon {
  color: #cf4520;
  fill: #e7751d;
  width: 30px;
  height: 25px;
  float: left;
  margin-right: 15px;
  margin-top: 15px; }

.pane-brochure-library-panel-pane-1 .views-field-field-brochure-file .file-icon {
  display: none; }

.pane-brochure-library-panel-pane-2 {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px; }

.view-locations-and-providers .form-control {
  max-width: none; }

.view-locations-and-providers .views-row {
  margin-bottom: 20px;
  padding-left: 30px; }
  .view-locations-and-providers .views-row:hover {
    color: #e7751d; }
  .view-locations-and-providers .views-row:before {
    content: '';
    position: absolute;
    left: 0;
    background: url(../images/icon-location.png) no-repeat;
    width: 20px;
    height: 30px;
    background-size: 18px; }

.pane-locations-and-providers-panel-pane-2 .view-content {
  display: none; }

.pane-locations-and-providers-panel-pane-2 .views-row:before {
  display: none; }

.node-webform {
  border-top: 1px solid #dddddd; }
  .node-webform h2 {
    display: none; }
  .node-webform .form-control {
    max-width: none; }
  .node-webform .col-sm-6:nth-child(odd) {
    padding-left: 0;
    padding-right: 0; }
    @media screen and (min-width: 768px) {
      .node-webform .col-sm-6:nth-child(odd) {
        padding-left: 0;
        padding-right: 20px; } }
  .node-webform .col-sm-6:nth-child(even) {
    padding-left: 0;
    padding-right: 0; }
    @media screen and (min-width: 768px) {
      .node-webform .col-sm-6:nth-child(even) {
        padding-left: 20px;
        padding-right: 0; } }
  .node-webform .webform-component-textarea textarea {
    max-width: none; }

.contact-anchor-wrapper {
  text-align: center; }
  .contact-anchor-wrapper .contact-anchor {
    font-weight: bold;
    font-size: 14px; }
  .contact-anchor-wrapper .btn-contact-anchor {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px; }
    @media screen and (min-width: 515px) {
      .contact-anchor-wrapper .btn-contact-anchor:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .contact-anchor-wrapper .btn-contact-anchor:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; } }

.view-conditions .views-exposed-form .views-exposed-widget {
  float: none; }
  .view-conditions .views-exposed-form .views-exposed-widget label {
    font-size: 13px; }

.view-conditions .views-row {
  border-bottom: 1px solid #dddddd; }
  .view-conditions .views-row a {
    display: block;
    position: relative;
    padding: 12px;
    font-weight: 700;
    text-decoration: none; }
    .view-conditions .views-row a:after {
      content: '\e80d';
      color: #cf4520;
      position: absolute;
      right: 12px;
      top: 16px;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
    .view-conditions .views-row a:hover {
      background: #f7f7f7; }

#locations-map {
  height: 280px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px; }

.address-location {
  cursor: pointer; }
  .address-location:hover {
    color: #e7751d; }

.pane-node-field-specialty-locations {
  margin-top: 40px; }
  .pane-node-field-specialty-locations .container {
    margin-left: 0;
    margin-right: 0; }
    .pane-node-field-specialty-locations .container .col-sm-6 {
      margin-bottom: 20px; }
      .pane-node-field-specialty-locations .container .col-sm-6:before {
        content: '';
        position: absolute;
        left: 0;
        background: url(../images/icon-location.png) no-repeat;
        width: 20px;
        height: 30px;
        background-size: 18px; }
      .pane-node-field-specialty-locations .container .col-sm-6 .address-location {
        padding-left: 10px; }

.pane-profiles-panel-pane-3,
.pane-profiles-panel-pane-4,
.pane-profiles-panel-pane-5,
.pane-profiles-panel-pane-6,
.pane-profiles-panel-pane-7,
.pane-profiles-panel-pane-9 {
  clear: both; }
  .pane-profiles-panel-pane-3 .views-row,
  .pane-profiles-panel-pane-4 .views-row,
  .pane-profiles-panel-pane-5 .views-row,
  .pane-profiles-panel-pane-6 .views-row,
  .pane-profiles-panel-pane-7 .views-row,
  .pane-profiles-panel-pane-9 .views-row {
    margin-bottom: 30px; }
    .pane-profiles-panel-pane-3 .views-row .views-field-field-profile-image,
    .pane-profiles-panel-pane-4 .views-row .views-field-field-profile-image,
    .pane-profiles-panel-pane-5 .views-row .views-field-field-profile-image,
    .pane-profiles-panel-pane-6 .views-row .views-field-field-profile-image,
    .pane-profiles-panel-pane-7 .views-row .views-field-field-profile-image,
    .pane-profiles-panel-pane-9 .views-row .views-field-field-profile-image {
      padding-bottom: 10px; }
    .pane-profiles-panel-pane-3 .views-row .views-field-field-professional-title,
    .pane-profiles-panel-pane-4 .views-row .views-field-field-professional-title,
    .pane-profiles-panel-pane-5 .views-row .views-field-field-professional-title,
    .pane-profiles-panel-pane-6 .views-row .views-field-field-professional-title,
    .pane-profiles-panel-pane-7 .views-row .views-field-field-professional-title,
    .pane-profiles-panel-pane-9 .views-row .views-field-field-professional-title {
      padding-bottom: 10px; }
    .pane-profiles-panel-pane-3 .views-row .display-name,
    .pane-profiles-panel-pane-3 .views-row .views-field-field-professional-title,
    .pane-profiles-panel-pane-3 .views-row .views-field-field-pops-profile-link,
    .pane-profiles-panel-pane-3 .views-row .views-field-field-vivo-profile,
    .pane-profiles-panel-pane-4 .views-row .display-name,
    .pane-profiles-panel-pane-4 .views-row .views-field-field-professional-title,
    .pane-profiles-panel-pane-4 .views-row .views-field-field-pops-profile-link,
    .pane-profiles-panel-pane-4 .views-row .views-field-field-vivo-profile,
    .pane-profiles-panel-pane-5 .views-row .display-name,
    .pane-profiles-panel-pane-5 .views-row .views-field-field-professional-title,
    .pane-profiles-panel-pane-5 .views-row .views-field-field-pops-profile-link,
    .pane-profiles-panel-pane-5 .views-row .views-field-field-vivo-profile,
    .pane-profiles-panel-pane-6 .views-row .display-name,
    .pane-profiles-panel-pane-6 .views-row .views-field-field-professional-title,
    .pane-profiles-panel-pane-6 .views-row .views-field-field-pops-profile-link,
    .pane-profiles-panel-pane-6 .views-row .views-field-field-vivo-profile,
    .pane-profiles-panel-pane-7 .views-row .display-name,
    .pane-profiles-panel-pane-7 .views-row .views-field-field-professional-title,
    .pane-profiles-panel-pane-7 .views-row .views-field-field-pops-profile-link,
    .pane-profiles-panel-pane-7 .views-row .views-field-field-vivo-profile,
    .pane-profiles-panel-pane-9 .views-row .display-name,
    .pane-profiles-panel-pane-9 .views-row .views-field-field-professional-title,
    .pane-profiles-panel-pane-9 .views-row .views-field-field-pops-profile-link,
    .pane-profiles-panel-pane-9 .views-row .views-field-field-vivo-profile {
      font-size: 13px; }
    .pane-profiles-panel-pane-3 .views-row:nth-child(3n+4),
    .pane-profiles-panel-pane-4 .views-row:nth-child(3n+4),
    .pane-profiles-panel-pane-5 .views-row:nth-child(3n+4),
    .pane-profiles-panel-pane-6 .views-row:nth-child(3n+4),
    .pane-profiles-panel-pane-7 .views-row:nth-child(3n+4),
    .pane-profiles-panel-pane-9 .views-row:nth-child(3n+4) {
      clear: both; }

.pane-profiles-panel-pane-3 .pane-title {
  margin-bottom: 20px; }

.sub-service-providers-title {
  margin-bottom: 20px;
  color: #777;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "1898Sans-Bold", sans-serif; }

.sub-service-provider {
  font-size: 13px; }
  .sub-service-provider .profile-image {
    padding-bottom: 10px; }
  .sub-service-provider .profile-titles {
    padding-bottom: 10px; }

.pane-profiles-panel-pane-4, .pane-profiles-panel-pane-9 {
  border-top: 1px solid #dddddd; }
  .pane-profiles-panel-pane-4:before, .pane-profiles-panel-pane-4:after, .pane-profiles-panel-pane-9:before, .pane-profiles-panel-pane-9:after {
    content: " ";
    display: table; }
  .pane-profiles-panel-pane-4:after, .pane-profiles-panel-pane-9:after {
    clear: both; }
  .pane-profiles-panel-pane-4 .pane-title, .pane-profiles-panel-pane-9 .pane-title {
    margin-bottom: 20px; }

.pane-profiles-panel-pane-7:before, .pane-profiles-panel-pane-7:after {
  content: " ";
  display: table; }

.pane-profiles-panel-pane-7:after {
  clear: both; }

.pane-profiles-panel-pane-7 .pane-title {
  margin-bottom: 20px; }

.pane-locations-and-providers-panel-pane-1 {
  margin-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #dddddd; }

.pane-node-field-sub-services {
  clear: both; }
  .pane-node-field-sub-services .element__hide {
    padding-bottom: 20px; }

#views-exposed-form-locations-and-providers-panel-pane-1 {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #dddddd; }
  #views-exposed-form-locations-and-providers-panel-pane-1 .views-submit-button {
    display: none; }
  #views-exposed-form-locations-and-providers-panel-pane-1 .views-exposed-form {
    border: 1px solid #dddddd;
    background: #f7f7f7;
    padding: 25px; }
  #views-exposed-form-locations-and-providers-panel-pane-1 div.filter-description {
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    color: #666666;
    letter-spacing: 2px;
    clear: both; }
  #views-exposed-form-locations-and-providers-panel-pane-1 .views-widget-filter-nid_1 {
    float: none;
    width: 100%;
    max-width: 400px;
    margin-bottom: 30px; }
    #views-exposed-form-locations-and-providers-panel-pane-1 .views-widget-filter-nid_1 .select2-container {
      width: 100% !important; }
    #views-exposed-form-locations-and-providers-panel-pane-1 .views-widget-filter-nid_1 .form-control {
      max-width: 50%; }

.pane-clinical-specialties-panel-pane-1 {
  clear: both;
  margin-bottom: 20px; }
  .pane-clinical-specialties-panel-pane-1 .pane-title {
    margin-bottom: 15px; }
  .pane-clinical-specialties-panel-pane-1 .views-row {
    border-bottom: 1px solid #dddddd; }
    .pane-clinical-specialties-panel-pane-1 .views-row:first-child {
      border-top: 1px solid #dddddd; }
    .pane-clinical-specialties-panel-pane-1 .views-row a {
      display: block;
      position: relative;
      padding: 12px 30px 12px 12px;
      font-weight: 700;
      text-decoration: none; }
      .pane-clinical-specialties-panel-pane-1 .views-row a:after {
        content: '\e80d';
        color: #cf4520;
        position: absolute;
        right: 12px;
        top: 16px;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
      .pane-clinical-specialties-panel-pane-1 .views-row a:hover {
        background: #f7f7f7; }

.pane-clinical-specialties-panel-pane-2 {
  clear: both;
  margin-bottom: 20px; }
  .pane-clinical-specialties-panel-pane-2 .pane-title {
    margin-bottom: 15px; }
  .pane-clinical-specialties-panel-pane-2 .condition-list {
    border-bottom: 1px solid #dddddd; }
    .pane-clinical-specialties-panel-pane-2 .condition-list:first-child {
      border-top: 1px solid #dddddd; }
    .pane-clinical-specialties-panel-pane-2 .condition-list a {
      display: block;
      position: relative;
      padding: 12px 30px 12px 12px;
      font-weight: 700;
      text-decoration: none; }
      .pane-clinical-specialties-panel-pane-2 .condition-list a:after {
        content: '\e80d';
        color: #cf4520;
        position: absolute;
        right: 12px;
        top: 16px;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
      .pane-clinical-specialties-panel-pane-2 .condition-list a:hover {
        background: #f7f7f7; }

.pane-clinical-specialties-panel-pane-3 {
  margin-bottom: 30px; }
  .pane-clinical-specialties-panel-pane-3 .select2-container {
    width: 100% !important; }

.pane-education-videos-panel-pane-1 h3 {
  clear: both;
  border-top: 1px solid #dddddd;
  padding-top: 20px;
  padding-bottom: 20px; }

.pane-education-videos-panel-pane-1 .views-row:nth-child(3n+3) {
  clear: both;
  padding-left: 0;
  padding-right: 10px; }

.pane-education-videos-panel-pane-1 .views-row:nth-child(3n+1) {
  padding-left: 5px;
  padding-right: 5px; }

.pane-education-videos-panel-pane-1 .views-row:nth-child(3n+2) {
  padding-left: 10px;
  padding-right: 0; }

.view-residents .views-row {
  margin-bottom: 20px; }
  .view-residents .views-row.views-row-odd {
    clear: both; }

.pane-residents-panel-pane-6 .pane-title {
  margin-bottom: 20px; }

.pane-residents-panel-pane-6 .view-residents .col-sm-3 {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .pane-residents-panel-pane-6 .view-residents .col-sm-3 {
      padding-left: 0;
      padding-right: 10px; } }

.pane-residents-panel-pane-6 .view-residents .col-sm-9 {
  padding-left: 0;
  padding-right: 0; }
  @media screen and (min-width: 768px) {
    .pane-residents-panel-pane-6 .view-residents .col-sm-9 {
      padding-left: 10px;
      padding-right: 0; } }

.wcc-connect-pane {
  padding: 30px 35px 30px;
  border: 1px solid #dddddd;
  background: #f7f7f7;
  height: 100%; }
  @media screen and (min-width: 768px) {
    .wcc-connect-pane {
      padding: 30px 100px 30px; } }
  .wcc-connect-pane .wcc-connect-callout {
    text-align: center;
    font-size: 28px;
    margin-top: 10px;
    font-family: "1898Sans-Bold", sans-serif;
    color: #b31b1b; }
    @media screen and (min-width: 768px) {
      .wcc-connect-pane .wcc-connect-callout {
        font-size: 40px; } }
    .wcc-connect-pane .wcc-connect-callout span {
      font-family: "1898Sans-Regular", sans-serif;
      color: #b31b1b; }
  .wcc-connect-pane form {
    /*input[type="submit"] {
            float: right;
            clear: both;
            margin-top: 10px;
        }*/ }
    .wcc-connect-pane form label {
      color: #666666;
      font-size: 14px;
      font-weight: 400; }
    .wcc-connect-pane form .form-control {
      max-width: none; }
    .wcc-connect-pane form .mychart-link {
      float: right;
      font-size: 13px; }
      .wcc-connect-pane form .mychart-link:after {
        display: none; }
    .wcc-connect-pane form .wcc-connect-footer {
      margin-top: 20px;
      text-align: right; }
      .wcc-connect-pane form .wcc-connect-footer input[type="submit"] {
        margin-left: 10px;
        font-size: 13px;
        padding: 5px 20px; }
      .wcc-connect-pane form .wcc-connect-footer a {
        font-size: 13px; }
        .wcc-connect-pane form .wcc-connect-footer a:after {
          display: none; }

.pane-wcmc-contact-pane .view-conditions .view-content, .pane-wcmc-contact-pane .view-clinical-specialties .view-content, .pane-wcmc-contact-pane .view-services .view-content {
  font-weight: 700;
  color: #666666;
  padding-bottom: 12.5px; }

/* Localist Calendar */
.localist-calendar-widget {
  margin-top: 20px; }
  .localist-calendar-widget .localist_minicalendar {
    position: relative; }
  .localist-calendar-widget .localist_minicalendar_page_heading {
    border: 1px solid #dddddd;
    height: 40px !important;
    border-bottom: none;
    color: #000000;
    margin-bottom: 0; }
    .localist-calendar-widget .localist_minicalendar_page_heading h1 {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 1.5rem !important;
      padding-bottom: 0;
      padding-top: 4px;
      color: #000000 !important; }
  .localist-calendar-widget .localist_minicalendar .localist_minicalendar_pager a {
    background: none !important;
    position: relative;
    font-size: 0;
    padding: 13px 10px;
    color: #b31b1b;
    border-bottom: none; }
    .localist-calendar-widget .localist_minicalendar .localist_minicalendar_pager a:before {
      color: #b31b1b;
      font-size: 1.3rem;
      content: ' \e810';
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      margin-left: .2em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .localist-calendar-widget .localist_minicalendar .localist_minicalendar_pager a.right:before {
      color: #b31b1b;
      font-size: 1.3rem;
      content: ' \e802';
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      margin-left: .2em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .localist-calendar-widget .localist_minicalendar_minicalendar {
    width: 100%;
    border: 1px solid #dddddd; }
    .localist-calendar-widget .localist_minicalendar_minicalendar .localist_minicalendar_dimday a {
      color: #666666; }
      .localist-calendar-widget .localist_minicalendar_minicalendar .localist_minicalendar_dimday a:hover {
        color: #fff; }
    .localist-calendar-widget .localist_minicalendar_minicalendar caption {
      display: none; }
    .localist-calendar-widget .localist_minicalendar_minicalendar tbody {
      border-top: none; }
    .localist-calendar-widget .localist_minicalendar_minicalendar th {
      background: #fff;
      color: #000;
      border: none;
      padding: 7px 0;
      font-size: 13px;
      font-weight: 600;
      /*&:last-child {
                    border-right: 1px solid #ccc;
                }*/ }
    .localist-calendar-widget .localist_minicalendar_minicalendar td {
      background-color: #f7f7f7;
      border: none;
      font-weight: normal;
      padding: 4px 0; }
      @media screen and (max-width: 359px) {
        .localist-calendar-widget .localist_minicalendar_minicalendar td.localist_minicalendar_today {
          background-color: #cf4520; }
          .localist-calendar-widget .localist_minicalendar_minicalendar td.localist_minicalendar_today a {
            width: auto;
            height: auto;
            color: #fff; } }
      @media screen and (min-width: 360px) {
        .localist-calendar-widget .localist_minicalendar_minicalendar td.localist_minicalendar_today a {
          background-color: #cf4520;
          color: #fff;
          font-weight: normal; } }
      .localist-calendar-widget .localist_minicalendar_minicalendar td a {
        height: 45px;
        line-height: 45px;
        width: 45px;
        display: inline-block;
        border-radius: 45px;
        border: none; }
        @media screen and (max-width: 359px) {
          .localist-calendar-widget .localist_minicalendar_minicalendar td a {
            width: auto;
            height: auto;
            line-height: inherit; } }
        .localist-calendar-widget .localist_minicalendar_minicalendar td a:hover {
          background-color: #cf4520;
          font-weight: normal; }
  .localist-calendar-widget .localist_minicalendar_events {
    margin-top: 20px; }
    .localist-calendar-widget .localist_minicalendar_events #lw {
      font-family: inherit; }
      .localist-calendar-widget .localist_minicalendar_events #lw ul {
        padding: 0; }
        .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe {
          padding: 0;
          border-bottom: none;
          margin-bottom: 10px;
          position: relative;
          padding-bottom: 20px;
          margin-bottom: 25px;
          border-bottom: 1px solid #dddddd; }
          .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe .lwd, .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe .lwl {
            font-size: inherit;
            line-height: inherit; }
          .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe .lwi0 {
            float: right;
            padding: 0;
            margin-left: 10px;
            margin-bottom: 15px; }
            .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe .lwi0 .lwi {
              padding: 0;
              border: none; }
          .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe .lwn0 {
            width: 100%;
            height: auto;
            display: block;
            text-align: left;
            padding: 0;
            margin-bottom: 10px;
            float: none;
            color: #666666;
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            /*
                        // background: url(../../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
                        background: url(../images/calendar.svg) no-repeat;
                        background-size: 100%;
                        background-position: 0 0;
                        // background-position: -300px -60px;
                        width: 50px;
                        height: 50px;
                        position: relative;
                        float: left;
                        margin-right: 5px;
                        padding: 18px 10px 0px;
                        font-size: 13px;
                        font-weight: 600;
                        text-align: center;
                        line-height: 1;*/ }

/* ---- Accordion CSS ---- */
.ctools-collapsible-processed .ctools-collapsible-handle h2 {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: #cf4520;
  border: 1px solid #ddd;
  border-width: 3px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600; }

.ctools-collapsible-processed .ctools-collapsible-handle h2:after {
  content: '-';
  width: 50px;
  display: block;
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  border-left: 3px solid #ddd; }

.ctools-collapsible-processed.ctools-collapsed .ctools-collapsible-handle h2:after {
  content: '+'; }

.ctools-collapsible-container .ctools-toggle {
  display: none; }

.front footer {
  padding-top: 0; }

.front .locations-callout-home {
  position: relative;
  overflow: hidden; }
  .front .locations-callout-home p {
    margin-bottom: 0;
    overflow: hidden;
    background: #cf4520; }
  .front .locations-callout-home:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  .front .locations-callout-home:hover a span {
    text-decoration: underline; }
  .front .locations-callout-home .locations-callout {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1; }
    .front .locations-callout-home .locations-callout:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%; }
    .front .locations-callout-home .locations-callout a {
      border-bottom: none;
      color: #fff;
      position: relative;
      display: block;
      width: 100%;
      height: 100%; }
      .front .locations-callout-home .locations-callout a span {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 36px;
        font-weight: 700; }
        @media screen and (max-width: 767px) {
          .front .locations-callout-home .locations-callout a span {
            font-size: 26px;
            width: 100%;
            text-align: center; } }
        .front .locations-callout-home .locations-callout a span:after {
          content: '\e80d';
          color: #fff;
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          text-align: center;
          /* opacity: .8; */
          /* For safety - reset parent styles, that can break glyph codes*/
          font-variant: normal;
          text-transform: none;
          /* fix buttons height, for twitter bootstrap */
          line-height: 1em;
          /* Animation center compensation - margins should be symmetric */
          /* remove if not needed */
          /* you can be more comfortable with increased icons size */
          /* font-size: 120%; */
          /* Font smoothing. That was taken from TWBS */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          /* Uncomment for 3D effect */
          /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
          font-size: 70%;
          padding-left: 10px;
          text-decoration: none; }
  .front .locations-callout-home img {
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    width: 100%;
    mix-blend-mode: multiply; }

.front .hidden-link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font: 0/0 a; }

.front .annual-brochure {
  position: relative; }
  .front .annual-brochure img {
    border: 2px solid #dddddd; }
  .front .annual-brochure .pdf-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .front .annual-brochure .pdf-icon .cta-icon {
      color: #cf4520;
      fill: #e7751d;
      width: 70px;
      height: 70px; }

.front .content-locations {
  background: #000000; }
  .front .content-locations .view-locations-and-providers .view-content {
    display: none; }
  .front .content-locations #locations-map {
    height: 250px;
    margin-top: 0;
    margin-bottom: 0; }
    @media screen and (min-width: 768px) {
      .front .content-locations #locations-map {
        height: 350px; } }

.front .content-top {
  -webkit-box-shadow: 0 15px 40px 0px #dddddd;
  box-shadow: 0 15px 40px 0px #dddddd;
  position: relative;
  z-index: 1; }

.front .middle-column .row:before {
  display: block; }

@media screen and (max-width: 767px) {
  .front .middle-column .row .col-sm-7, .front .middle-column .row .col-sm-5 {
    width: 100%; } }

.front .middle-column .pane-bundle-text {
  text-align: center;
  padding-bottom: 20px; }

.front .middle-column .wcc-connect {
  padding: 70px 35px 30px;
  border: 1px solid #dddddd;
  background: #f7f7f7;
  height: 100%;
  flex: 1;
  text-align: center; }
  .front .middle-column .wcc-connect > a.appointment-link {
    white-space: nowrap;
    border: 3px solid #e7751d;
    border-radius: 22px;
    display: inline-block;
    margin-bottom: 15px;
    padding: 8px 32px 8px 32px; }
    .front .middle-column .wcc-connect > a.appointment-link:after {
      content: "";
      padding: 0; }
    .front .middle-column .wcc-connect > a.appointment-link .make-appt {
      font-size: 16px; }
  .front .middle-column .wcc-connect .phone {
    display: block;
    text-align: center;
    font-size: 32px;
    font-family: "1898Sans-Bold", sans-serif;
    color: #000000; }
  .front .middle-column .wcc-connect .wcc-connect-callout {
    text-align: center;
    font-size: 28px;
    margin-top: 10px;
    font-family: "1898Sans-Bold", sans-serif;
    color: #b31b1b; }
    .front .middle-column .wcc-connect .wcc-connect-callout span {
      font-family: "1898Sans-Regular", sans-serif;
      color: #b31b1b; }
  .front .middle-column .wcc-connect .wcc-connect-callout-subtext {
    text-align: center; }
    .front .middle-column .wcc-connect .wcc-connect-callout-subtext a {
      color: #b31b1b; }
  .front .middle-column .wcc-connect .cta-container {
    text-align: center; }
    .front .middle-column .wcc-connect .cta-container .cta-icon {
      color: #cf4520;
      fill: #e7751d;
      width: 60px;
      height: 60px; }
  .front .middle-column .wcc-connect hr.hr-small {
    width: 80%; }
  .front .middle-column .wcc-connect form label {
    color: #666666;
    font-size: 14px;
    font-weight: 400; }
  .front .middle-column .wcc-connect form .mychart-link {
    float: right;
    color: #666666;
    font-size: 13px; }
    .front .middle-column .wcc-connect form .mychart-link:after {
      display: none; }
  .front .middle-column .wcc-connect form input[type="submit"] {
    float: right;
    clear: both;
    margin-top: 10px; }

.front .events-pane {
  margin-top: 50px;
  margin-bottom: 50px; }
  .front .events-pane h3.pane-title {
    margin-bottom: 20px;
    color: #666666;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase; }

.front .localist-calendar-widget .all-events-btn {
  clear: both;
  text-align: center;
  padding-top: 15px; }
  @media screen and (min-width: 768px) {
    .front .localist-calendar-widget .all-events-btn {
      width: 50%; } }

.front .localist-calendar-widget .localist_minicalendar_nav {
  display: none; }

@media screen and (min-width: 768px) {
  .front .localist-calendar-widget .localist_minicalendar {
    float: left;
    width: 48%;
    margin-right: 4%; } }

@media screen and (min-width: 768px) {
  .front .localist-calendar-widget .localist_minicalendar_events {
    float: left;
    width: 48%;
    margin-top: 0; } }

.front .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe {
  padding-left: 40px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #dddddd; }
  .front .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe:last-child {
    border-bottom: none; }
  .front .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe:before {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background: url(../../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/calendar.svg);
    width: 30px;
    height: 30px;
    background-size: 30px 30px; }
  .front .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe .lwn a {
    font-size: 18px;
    line-height: 22px; }
  .front .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe .lwn .lwn0 {
    background: none;
    width: 100%;
    height: auto;
    display: block;
    text-align: left;
    padding: 0;
    margin-bottom: 10px;
    float: none;
    color: #666666;
    font-size: 13px;
    text-transform: uppercase; }
  .front .localist-calendar-widget .localist_minicalendar_events #lw ul li.lwe .lwl {
    padding-top: 15px;
    font-size: 14px;
    line-height: 18px; }

.front .callout-middle {
  padding: 80px 20px;
  color: #fff;
  position: relative;
  background: #000000;
  /*background: url(../images/for_professionals_bg.jpg) no-repeat;
        background-size: 50% 100%;
        background-position: right; */
  /*&:before {
            content: '';
            background: url(../images/for_professionals_bg.jpg) no-repeat;
            background-size: cover;
            position: absolute;
            left: 50%;
            top: 0;
            width: 50%;
            min-height: 460px;
        }

        &:after {
            content: '';
            background: url(../images/for_professionals_bg.jpg) no-repeat;
            background-size: cover;
            position: absolute;
            left: 50%;
            top: 0;
            width: 50%;
            min-height: 460px;
        }*/ }
  .front .callout-middle .callout-middle-bg-left {
    background: url(../images/ForPatients.jpg) no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%; }
    @media screen and (min-width: 768px) {
      .front .callout-middle .callout-middle-bg-left {
        left: 0;
        top: 0;
        width: 50%;
        height: 100%; } }
  .front .callout-middle .callout-middle-bg-right {
    background: url(../images/for_professionals_bg.jpg) no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 50%; }
    @media screen and (min-width: 768px) {
      .front .callout-middle .callout-middle-bg-right {
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%; } }
  .front .callout-middle .col-sm-6 {
    height: 300px;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s; }
    .front .callout-middle .col-sm-6 .callout-container {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
      -ms-transform: translateY(-50%);
      /* IE 9 */
      transform: translateY(-50%);
      /* IE 10, Fx 16+, Op 12.1+ */ }
      @media screen and (min-width: 768px) {
        .front .callout-middle .col-sm-6 .callout-container {
          padding: 0 60px; } }
    .front .callout-middle .col-sm-6:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background: #cf4520;
      mix-blend-mode: multiply; }
    .front .callout-middle .col-sm-6 h2 {
      text-align: center; }
    .front .callout-middle .col-sm-6 h2, .front .callout-middle .col-sm-6 p {
      color: #fff;
      position: relative; }
    @media screen and (min-width: 768px) {
      .front .callout-middle .col-sm-6 p {
        display: none; } }
    .front .callout-middle .col-sm-6 .cta-icon-container {
      position: relative;
      text-align: center; }
      .front .callout-middle .col-sm-6 .cta-icon-container .cta-icon {
        position: relative;
        margin: 0 auto;
        color: #cf4520;
        fill: #e7751d;
        width: 70px;
        height: 70px; }
    @media screen and (min-width: 768px) {
      .front .callout-middle .col-sm-6:hover:before {
        background: #b31b1b; }
      .front .callout-middle .col-sm-6:hover .cta-icon {
        color: #fff;
        fill: #fff; }
      .front .callout-middle .col-sm-6:hover p {
        display: block; } }

.front .pane-clinical-specialties-panel-pane-1 {
  padding-top: 40px;
  margin-bottom: 30px; }
  .front .pane-clinical-specialties-panel-pane-1 h3.pane-title {
    margin-bottom: 20px;
    color: #666666;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px; }
  .front .pane-clinical-specialties-panel-pane-1 .views-row {
    border-bottom: 1px solid #dddddd; }
    .front .pane-clinical-specialties-panel-pane-1 .views-row:first-child {
      border-top: 1px solid #dddddd; }
    .front .pane-clinical-specialties-panel-pane-1 .views-row a {
      display: block;
      position: relative;
      padding: 12px 30px 12px 12px;
      text-decoration: none; }
      .front .pane-clinical-specialties-panel-pane-1 .views-row a:after {
        content: '\e80d';
        color: #cf4520;
        position: absolute;
        right: 12px;
        top: 16px;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
      .front .pane-clinical-specialties-panel-pane-1 .views-row a:hover {
        -webkit-box-shadow: 0 0 6px 2px #dddddd;
        box-shadow: 0 0 6px 2px #dddddd;
        background: #fff; }

.front .home-callout-residency {
  background: #000000;
  padding-top: 80px;
  padding-bottom: 80px;
  background: url(../images/residency_program_callout.jpg) no-repeat top center;
  background-size: cover; }
  @media screen and (min-width: 768px) {
    .front .home-callout-residency {
      padding-top: 125px;
      padding-bottom: 125px; } }
  .front .home-callout-residency .callout-background {
    color: #fff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px; }
    .front .home-callout-residency .callout-background:before {
      content: '';
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background: #e7751d;
      mix-blend-mode: multiply; }
    .front .home-callout-residency .callout-background h2 {
      color: #fff;
      margin-bottom: 20px;
      font-size: 20px; }
      @media screen and (min-width: 768px) {
        .front .home-callout-residency .callout-background h2 {
          font-size: 30px; } }
      .front .home-callout-residency .callout-background h2 a {
        color: #fff; }
    .front .home-callout-residency .callout-background p {
      font-size: 16px; }
      @media screen and (min-width: 768px) {
        .front .home-callout-residency .callout-background p {
          font-size: 24px; } }

.front .home-doormat {
  padding-top: 50px;
  padding-bottom: 50px;
  /*.col-md-4, .col-md-3 {
            .doormat-entry {
                position: relative;
            }
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: $wcm-bright-orange;// rgba(232, 119, 34, 0.8);
                mix-blend-mode: multiply;
            }
        }*/
  /*.col-md-4:nth-child(2), .col-md-3:nth-child(2) {
            &:before {
                background: $wcm-yellow;
            }
            // background: rgba(255, 199, 44, 0.8);
            // background: $wcm-yellow;
        }
        .col-md-4:nth-child(3), .col-md-3:nth-child(3) {
            &:before {
                background: $wcm-dark-orange;
            }
            // background: rgba(207, 69, 32, 0.8);
            // background: $wcm-dark-orange;
        }*/ }
  @media screen and (max-width: 767px) {
    .front .home-doormat {
      padding-left: 40px;
      padding-right: 40px; } }
  .front .home-doormat .doormat .row.is-flex {
    display: block; }
    .front .home-doormat .doormat .row.is-flex:before {
      display: block; }
    @media screen and (min-width: 992px) {
      .front .home-doormat .doormat .row.is-flex {
        display: flex; } }
  .front .home-doormat .col-md-4, .front .home-doormat .col-md-3 {
    border-bottom: 1px solid #dddddd; }
    .front .home-doormat .col-md-4:last-child, .front .home-doormat .col-md-3:last-child {
      border-bottom: none; }
    @media screen and (min-width: 992px) {
      .front .home-doormat .col-md-4, .front .home-doormat .col-md-3 {
        border-bottom: none;
        border-right: 1px solid #dddddd; }
        .front .home-doormat .col-md-4:last-child, .front .home-doormat .col-md-3:last-child {
          border-right: none; } }
    .front .home-doormat .col-md-4 h3, .front .home-doormat .col-md-3 h3 {
      margin-bottom: 20px;
      font-size: 24px;
      /*@include breakpoint($sm) {
                    font-size: 30px;
                }*/ }
      .front .home-doormat .col-md-4 h3 a.external-link:after, .front .home-doormat .col-md-3 h3 a.external-link:after {
        display: inline-block;
        content: '';
        background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg);
        background-size: 15px 15px;
        height: 15px;
        width: 15px;
        position: relative;
        top: 4px; }
    .front .home-doormat .col-md-4 .doormat-entry, .front .home-doormat .col-md-3 .doormat-entry {
      padding: 0px 0px 10px; }
      @media screen and (min-width: 992px) {
        .front .home-doormat .col-md-4 .doormat-entry, .front .home-doormat .col-md-3 .doormat-entry {
          padding: 0px 50px 30px; } }
    .front .home-doormat .col-md-4 .doormat-entry__description, .front .home-doormat .col-md-3 .doormat-entry__description {
      font-size: 16px; }
      @media screen and (min-width: 768px) {
        .front .home-doormat .col-md-4 .doormat-entry__description, .front .home-doormat .col-md-3 .doormat-entry__description {
          font-size: 18px; } }
    .front .home-doormat .col-md-4:hover:before, .front .home-doormat .col-md-3:hover:before {
      background: #b31b1b !important; }
  .front .home-doormat .col-md-3 h3 {
    font-size: 24px; }
  .front .home-doormat .col-md-3 .doormat-entry {
    padding: 0px 0px 10px; }
    @media screen and (min-width: 992px) {
      .front .home-doormat .col-md-3 .doormat-entry {
        padding: 0px 10px 30px; } }
  @media screen and (min-width: 768px) {
    .front .home-doormat .col-md-3 .doormat-entry__description {
      font-size: 18px; } }
  .front .home-doormat .col-md-4:nth-child(1), .front .home-doormat .col-md-3:nth-child(1) {
    /*.doormat-entry {
                position: relative;
            }
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: $wcm-bright-orange;// rgba(232, 119, 34, 0.8);
                mix-blend-mode: multiply;
            }*/ }

.front .btn-home {
  font-size: 13px;
  padding: 5px 20px; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }
